<template>
  <div
    class="navbar-container d-flex content align-items-center"
    style="background: linear-gradient(270deg, #525252 0%, black 100%);"
  >
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            class="text-white"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="align-items-center flex-grow-1 d-none d-lg-flex">
      <h4
        style="font-weight: bold; margin:1px 0px 0px 7px"
        class="time text-white"
        v-text="currentTime"
      />
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <user-dropdown />
      <b-avatar
        size="40"
        rounded
        variant="light-warning"
        class="badge-minimal cursor-pointer mr-1"
      >
        <i
          class="fas fa-door-open fa-2x"
          @click="logout"
        />
      </b-avatar>
    </b-navbar-nav>
  </div>
</template>

<script>
import { BLink, BNavbarNav, BAvatar } from 'bootstrap-vue'
import moment from 'moment-timezone'
import { avatarText } from '@core/utils/filter'
import UserDropdown from './components/UserDropdown.vue'
import useJwt from '@/auth/jwt/useJwt'
import { initialAbility } from '@/libs/acl/config'

export default {
  components: {
    BLink,
    BNavbarNav,
    UserDropdown,
    BAvatar,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      currentTime: null,
      avatarText,
    }
  },
  created() {
    this.currentTime = moment().format('LTS')
    setInterval(() => this.updateCurrentTime(), 1 * 1000)
  },
  methods: {
    updateCurrentTime() {
      this.currentTime = moment().format('HH:mm:ss')
    },
    async logout() {
      await this.$http.post('https://api.rmtlotto.com/api/auth/logout')
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

      // Remove userData from localStorage
      localStorage.removeItem('userData')

      // Reset ability
      this.$ability.update(initialAbility)

      // Redirect to login page
      this.$router.push({ name: 'auth-login' })
    },
  },
}
</script>
